import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";

import { SiteGenerator } from "../components/craftjs";
import SiteEditMenu from "../components/siteEditMenu";
import { EntityApi, useAuth } from "@unity/components";
import config from "../config";

export default function SiteEditor({ readOnly }) {
  const { id } = useParams();
  const { url } = useRouteMatch();
  const { auth } = useAuth();

  const isNew = url === "/site-creator/new";

  const [site, setSite] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const res = await EntityApi.getEntity(id, config.entitySiteTypeId);
      if (res && res.success) {
        setSite(res.data);
      }
    };
    if (id) {
      getData();
    }
  }, [id]);

  return (
    <Box sx={{ pt: 1, pl: 1 }} className="tour-siteeditor">
      {isNew || (site && site.created_by === auth.id) ? (
        <SiteGenerator
          site={site}
          setSite={setSite}
          readOnly={readOnly}
          onSaveClick={() => setOpen(true)}
        />
      ) : null}
      <SiteEditMenu
        site={site}
        open={open}
        onClose={() => setOpen(false)}
        isNew={isNew}
      />
    </Box>
  );
}
