import React, { useRef } from "react";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Slider,
  TextField,
  Stack,
  RadioGroup,
  Radio,
  MenuItem,
  Select,
  Input,
} from "@mui/material";
import { useNode, useEditor } from "@craftjs/core";
import { Resizable } from "re-resizable";

export const Container = ({
  padding,
  margin,
  background,
  height,
  width,
  flexDirection,
  justifyContent,
  alignItems,
  gap,
  children,
}) => {
  const {
    connectors: { connect, drag },
    actions: { setProp },
  } = useNode();

  const resizable = useRef(null);

  return (
    <Resizable
      ref={(ref) => {
        if (ref) {
          resizable.current = ref;
          connect(resizable.current.resizable);
        }
      }}
      style={{
        backgroundColor: background,
        padding,
        margin,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      size={{ width, height }}
      onResizeStop={(e, direction, ref, d) => {
        setProp((props) => {
          props.width += d.width;
          props.height += d.height;
        });
      }}
    >
      <div
        style={{
          width: "95%",
          height: "95%",
          flexDirection,
          display: "flex",
          justifyContent,
          alignItems,
          gap,
          cursor: "grab",
        }}
        ref={drag}
      >
        {children}
      </div>
    </Resizable>
  );
};

export const ContainerSettings = () => {
  const {
    background,
    padding,
    margin,
    width,
    height,
    flexDirection,
    justifyContent,
    alignItems,
    gap,
    actions: { setProp },
  } = useNode((node) => ({
    background: node.data.props.background,
    padding: node.data.props.padding,
    margin: node.data.props.margin,
    width: node.data.props.width,
    height: node.data.props.height,
    flexDirection: node.data.props.flexDirection,
    justifyContent: node.data.props.justifyContent,
    alignItems: node.data.props.alignItems,
    gap: node.data.props.gap,
  }));

  return (
    <Stack spacing={1}>
      <TextField
        label="width"
        value={width}
        onChange={(e) => setProp((props) => (props.width = e.target.value))}
        size="small"
      />
      <TextField
        label="height"
        value={height}
        onChange={(e) => setProp((props) => (props.height = e.target.value))}
        size="small"
      />
      <FormControl
        size="small"
        fullWidth={true}
        margin="normal"
        component="fieldset"
      >
        <FormLabel component="legend">Background</FormLabel>
        <Input
          type="color"
          size="small"
          disableUnderline
          value={background}
          onChange={(e) => {
            setProp((props) => (props.background = e.target.value), 500);
          }}
          sx={{ ".MuiInput-input": { height: 30, width: 60 } }}
        />
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">
          Padding
          <span
            style={{ color: "#000000", paddingLeft: 10 }}
          >{`${padding}px`}</span>
        </FormLabel>
        <Slider
          value={padding}
          onChange={(_, value) =>
            setProp((props) => (props.padding = value), 500)
          }
          size="small"
        />
      </FormControl>
      <FormControl
        fullWidth={true}
        margin="normal"
        component="fieldset"
        size="small"
      >
        <FormLabel component="legend">
          Margin
          <span
            style={{ color: "#000000", paddingLeft: 10 }}
          >{`${margin}px`}</span>
        </FormLabel>
        <Slider
          value={margin}
          onChange={(_, value) =>
            setProp((props) => (props.margin = value), 500)
          }
          size="small"
        />
      </FormControl>
      <FormControl size="small">
        <FormLabel id="demo-row-radio-buttons-group-label">
          Flex direction
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={flexDirection}
          onChange={(e) =>
            setProp((props) => (props.flexDirection = e.target.value))
          }
          size="small"
        >
          <FormControlLabel value="row" control={<Radio />} label="Row" />
          <FormControlLabel value="column" control={<Radio />} label="Column" />
        </RadioGroup>
      </FormControl>
      <FormControl size="small">
        <FormLabel id="justify">Justify content</FormLabel>
        <Select
          labelId="justify"
          value={justifyContent}
          onChange={(e) =>
            setProp((props) => (props.justifyContent = e.target.value))
          }
        >
          <MenuItem value="flex-start">Flex start</MenuItem>
          <MenuItem value="center">Center</MenuItem>
          <MenuItem value="flex-end">Flex end</MenuItem>
          <MenuItem value="space-between">Space between</MenuItem>
          <MenuItem value="space-around">Space around</MenuItem>
          <MenuItem value="space-evenly">Space evenly</MenuItem>
        </Select>
      </FormControl>
      <FormControl size="small">
        <FormLabel id="align">Align items</FormLabel>
        <Select
          labelId="align"
          value={alignItems}
          onChange={(e) =>
            setProp((props) => (props.alignItems = e.target.value))
          }
        >
          <MenuItem value="flex-start">Flex start</MenuItem>
          <MenuItem value="center">Center</MenuItem>
          <MenuItem value="flex-end">Flex end</MenuItem>
          <MenuItem value="stretch">Stretch</MenuItem>
        </Select>
      </FormControl>
      <FormControl size="small">
        <FormLabel id="gap">Spacing</FormLabel>
        <Slider
          value={gap}
          onChange={(_, value) => setProp((props) => (props.gap = value), 500)}
          size="small"
        />
      </FormControl>
    </Stack>
  );
};

export const ContainerDefaultProps = {
  background: "#ffffff",
  padding: 3,
  margin: 0,
  width: 700,
  height: "auto",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: 10,
};

Container.craft = {
  props: ContainerDefaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    settings: ContainerSettings,
  },
};
