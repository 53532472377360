import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  Slider,
  TextField,
  Stack,
  RadioGroup,
  Radio,
  MenuItem,
  Select,
  Input,
  Box,
} from "@mui/material";
import { useNode } from "@craftjs/core";
import { useCraftjsComponentStyle } from "../../../utils/useCraftjsComponentStyle";

export const Container = ({
  padding,
  margin,
  background,
  height,
  width,
  flexDirection,
  justifyContent,
  alignItems,
  gap,
  children,
}) => {
  const {
    id,
    isHover,
    connectors: { connect, drag },
  } = useNode((node) => ({
    isHover: node.events.hovered,
  }));

  const nodeStyle = useCraftjsComponentStyle(id, isHover);

  const [dimensions, setDimensions] = useState({
    width: "100px",
    height: "100px",
  });

  useEffect(() => {
    const convertDimensions = (value) => {
      if (
        value &&
        typeof value === "string" &&
        (value.indexOf("%") !== -1 || value === "auto")
      ) {
        return value;
      } else {
        const myNum = Number(value);
        if (Number.isInteger(myNum)) {
          return `${value}px`;
        } else {
          return "100px";
        }
      }
    };
    setDimensions({
      height: convertDimensions(height),
      width: convertDimensions(width),
    });
  }, [height, width]);

  return (
    <div
      style={{
        width: dimensions.width,
        height: dimensions.height,
        flexDirection,
        display: "flex",
        boxSizing: "border-box",
        justifyContent,
        alignItems,
        padding,
        margin,
        backgroundColor: background,
        gap,
        ...nodeStyle,
      }}
      ref={(ref) => connect(drag(ref))}
    >
      {children}
    </div>
  );
};

export const ContainerSettings = () => {
  const {
    background,
    padding,
    margin,
    width,
    height,
    flexDirection,
    justifyContent,
    alignItems,
    gap,
    actions: { setProp },
  } = useNode((node) => ({
    background: node.data.props.background,
    padding: node.data.props.padding,
    margin: node.data.props.margin,
    width: node.data.props.width,
    height: node.data.props.height,
    flexDirection: node.data.props.flexDirection,
    justifyContent: node.data.props.justifyContent,
    alignItems: node.data.props.alignItems,
    gap: node.data.props.gap,
  }));

  return (
    <Stack spacing={1} className="tour-sitecontaineroverview">
      <Stack spacing={1} direction="row">
        <TextField
          label="width"
          value={width}
          onChange={(e) => setProp((props) => (props.width = e.target.value))}
          size="small"
          className="tour-sitecontainerwidth"
          sx={{ width: 150 }}
        />
        <TextField
          label="height (*type 'auto' to fit content)"
          value={height}
          onChange={(e) => setProp((props) => (props.height = e.target.value))}
          size="small"
          className="tour-sitecontainerheight"
          sx={{ width: 150 }}
        />
      </Stack>
      <Box sx={{ display: "flex", gap: 2 }}>
        <FormControl
          margin="dense"
          component="fieldset"
          size="small"
          className="tour-sitecontainerpadding"
          sx={{ width: 130 }}
        >
          <FormLabel component="legend">
            Padding
            <span
              style={{ color: "#000000", paddingLeft: 10 }}
            >{`${padding}px`}</span>
          </FormLabel>
          <Slider
            value={padding}
            onChange={(_, value) =>
              setProp((props) => (props.padding = value), 500)
            }
            size="small"
          />
        </FormControl>
        <FormControl
          margin="dense"
          component="fieldset"
          size="small"
          className="tour-sitecontainermargin"
          sx={{ width: 130 }}
        >
          <FormLabel component="legend">
            Margin
            <span
              style={{ color: "#000000", paddingLeft: 10 }}
            >{`${margin}px`}</span>
          </FormLabel>
          <Slider
            value={margin}
            onChange={(_, value) =>
              setProp((props) => (props.margin = value), 500)
            }
            size="small"
          />
        </FormControl>
      </Box>
      <FormControl size="small" className="tour-sitecontainerflex">
        <FormLabel id="demo-row-radio-buttons-group-label">
          Flex direction
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={flexDirection}
          onChange={(e) =>
            setProp((props) => (props.flexDirection = e.target.value))
          }
          size="small"
        >
          <FormControlLabel value="row" control={<Radio />} label="Row" />
          <FormControlLabel value="column" control={<Radio />} label="Column" />
        </RadioGroup>
      </FormControl>
      <Stack spacing={1} direction="row">
        <FormControl
          size="small"
          className="tour-sitecontainerjustify"
          sx={{ width: 150 }}
        >
          <FormLabel id="justify">Justify content</FormLabel>
          <Select
            labelId="justify"
            value={justifyContent}
            onChange={(e) =>
              setProp((props) => (props.justifyContent = e.target.value))
            }
          >
            <MenuItem value="flex-start">Flex start</MenuItem>
            <MenuItem value="center">Center</MenuItem>
            <MenuItem value="flex-end">Flex end</MenuItem>
            <MenuItem value="space-between">Space between</MenuItem>
            <MenuItem value="space-around">Space around</MenuItem>
            <MenuItem value="space-evenly">Space evenly</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          size="small"
          className="tour-sitecontaineralign"
          sx={{ width: 150 }}
        >
          <FormLabel id="align">Align items</FormLabel>
          <Select
            labelId="align"
            value={alignItems}
            onChange={(e) =>
              setProp((props) => (props.alignItems = e.target.value))
            }
          >
            <MenuItem value="flex-start">Flex start</MenuItem>
            <MenuItem value="center">Center</MenuItem>
            <MenuItem value="flex-end">Flex end</MenuItem>
            <MenuItem value="stretch">Stretch</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <FormControl
          size="small"
          margin="dense"
          className="tour-sitecontainerspacing"
          component="fieldset"
          sx={{ width: 130 }}
        >
          <FormLabel id="gap">Spacing</FormLabel>
          <Slider
            value={gap}
            onChange={(_, value) =>
              setProp((props) => (props.gap = value), 500)
            }
            size="small"
          />
        </FormControl>
        <FormControl
          size="small"
          fullWidth={true}
          margin="normal"
          component="fieldset"
          className="tour-sitecontainerbackground"
        >
          <FormLabel component="legend">Background</FormLabel>
          <Input
            type="color"
            size="small"
            disableUnderline
            value={background}
            onChange={(e) => {
              setProp((props) => (props.background = e.target.value), 500);
            }}
            sx={{ ".MuiInput-input": { height: 30, width: 60 } }}
          />
        </FormControl>
      </Box>
    </Stack>
  );
};

export const ContainerDefaultProps = {
  background: "#ffffff",
  padding: 3,
  margin: 0,
  width: 700,
  height: "auto",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: 10,
};

Container.craft = {
  props: ContainerDefaultProps,
  rules: {
    canDrag: () => true,
  },
  related: {
    settings: ContainerSettings,
  },
};
