import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ContentCopy from "@mui/icons-material/ContentCopy";

import { EntityApi, DeleteButtonComponent, useAuth } from "@unity/components";
import config from "../config";
import { useApiLoader } from "../services/apiLoaderContext";

export default function SiteCardMenu({ site, open, onClose, setUpdate }) {
  const { setSnackbar } = useApiLoader();
  const { checkPermission } = useAuth();
  const canDelete = checkPermission(`delete_site_${site?.permission}`);
  const canUpdate = checkPermission(`update_site_${site?.permission}`);
  const canDeploy = checkPermission(`deploy_site_${site?.permission}`);
  const canUndeploy = checkPermission(`undeploy_site_${site?.permission}`);

  const [openShare, setOpenShare] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleRes = (res) => {
    if (res && res.success) {
      setSnackbar({ open: true, success: true, message: "success" });
    } else {
      setSnackbar({
        open: true,
        success: false,
        message: "There was an error with your request",
      });
    }
    onClose();
    setOpenShare(false);
    setOpenDelete(false);
    setUpdate((prev) => !prev);
  };

  const handleDelete = async () => {
    const res = await EntityApi.deleteEntity(site._id, config.entitySiteTypeId);
    handleRes(res);
  };

  const handleDeploy = async (deploy) => {
    const obj = {
      type_id: config.entitySiteTypeId,
      entity: {
        deployed: deploy,
      },
    };
    const res = await EntityApi.putEntity(site._id, obj);
    handleRes(res);
  };

  const onShareClick = () => {
    setOpenShare(true);
    onClose();
  };

  const onDeployClick = () => {
    handleDeploy(site.deployed === "true" ? "false" : "true");
    onClose();
  };

  const onDeleteClick = () => {
    setOpenDelete(true);
    onClose();
  };

  return (
    <>
      <Menu anchorEl={open} open={Boolean(open)} onClose={onClose}>
        {site.deployed === "true" ? (
          <MenuItem onClick={onShareClick}>Share</MenuItem>
        ) : null}
        {(site.deployed && canUndeploy) || canDeploy ? (
          <MenuItem onClick={onDeployClick}>
            {site.deployed === "true" ? "Undeploy" : "Deploy"}
          </MenuItem>
        ) : null}
        {canUpdate ? (
          <MenuItem
            to={`/site-creator/edit/${site._id}`}
            component={RouterLink}
          >
            Edit
          </MenuItem>
        ) : null}
        {canDelete ? <MenuItem onClick={onDeleteClick}>Delete</MenuItem> : null}
      </Menu>
      <Dialog
        open={openShare}
        onClose={() => setOpenShare(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Stack spacing={2} direction="row" alignItems="center">
            <Link
              noWrap
              sx={{ display: "block" }}
              target="_blank"
              component={RouterLink}
              to={`/site-creator/deployed/${site._id}`}
            >{`${config.clientURL}/site-creator/deployed/${site._id}`}</Link>
            <IconButton
              onClick={() => {
                setSnackbar({
                  open: true,
                  message: "Copied to clipboard!",
                  success: true,
                });
                navigator.clipboard.writeText(
                  `${config.clientURL}/site-creator/deployed/${site._id}`
                );
                setOpenShare(false);
              }}
            >
              <ContentCopy />
            </IconButton>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenShare(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
        <DialogTitle sx={{ color: (theme) => theme.palette.error.main }}>
          Are you sure you want to delete this site?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
          <DeleteButtonComponent onClick={handleDelete}>
            Delete
          </DeleteButtonComponent>
        </DialogActions>
      </Dialog>
    </>
  );
}
