import React from "react";
import { useNode } from "@craftjs/core";
import { FormControl, FormLabel, Slider, Box } from "@mui/material";

import { useCraftjsComponentStyle } from "../../../utils/useCraftjsComponentStyle";

export const Divider = ({ height, width, ...props }) => {
  const {
    id,
    isHover,
    connectors: { connect, drag },
  } = useNode((node) => ({
    isHover: node.events.hovered,
  }));
  const nodeStyle = useCraftjsComponentStyle(id, isHover);

  return (
    <Box
      ref={(ref) => connect(drag(ref))}
      {...props}
      sx={{
        height: `${height}px`,
        width: width ? `${width}px` : "100%",
        bgcolor: "transparent",
        ...nodeStyle,
      }}
    />
  );
};

export const DividerSettings = () => {
  const {
    actions: { setProp },
    height,
    width,
  } = useNode((node) => ({
    height: node.data.props.height,
    width: node.data.props.width,
  }));

  return (
    <div>
      <FormControl size="small" component="fieldset" sx={{ width: "100%" }}>
        <FormLabel component="legend">{`Height: ${height || 20}px`}</FormLabel>
        <Slider
          value={height || 30}
          step={5}
          min={1}
          max={600}
          size="small"
          onChange={(_, value) => {
            setProp((props) => (props.height = value), 1000);
          }}
        />
      </FormControl>
      <FormControl size="small" component="fieldset" sx={{ width: "100%" }}>
        <FormLabel component="legend">{`Width: ${width || 20}px`}</FormLabel>
        <Slider
          value={width || 30}
          step={5}
          min={1}
          max={600}
          size="small"
          onChange={(_, value) => {
            setProp((props) => (props.width = value), 1000);
          }}
        />
      </FormControl>
    </div>
  );
};

const DividerDefaultProps = {
  height: 20,
  width: 20,
};

Divider.craft = {
  props: DividerDefaultProps,
  related: {
    settings: DividerSettings,
  },
};
