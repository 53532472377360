import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  EntityApi,
  PermissionDropdownComponent,
  useAuth,
} from "@unity/components";
import config from "../config";
import { useApiLoader } from "../services/apiLoaderContext";

export default function SiteEditMenu({ site, open, onClose, isNew }) {
  const { setSnackbar, setLoading } = useApiLoader();
  const history = useHistory();
  const { checkPermission } = useAuth();

  const canDeploy = checkPermission(`deploy_site_${site?.permission}`);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [permission, setPermission] = useState("");

  useEffect(() => {
    if (site?.name) setName(site.name);
    if (site?.description) setDescription(site.description);
    if (site?.permission) setPermission(site.permission);
  }, [site]);

  const handleRes = (res) => {
    if (res && res.success) {
      setSnackbar({ open: true, success: true, message: "success" });
      history.push("/site-creator/index");
    } else {
      setSnackbar({
        open: true,
        success: false,
        message: "There was an error with your request",
      });
    }
  };

  const handleSave = async (isDeploy) => {
    try {
      setLoading(true);
      const obj = {
        type_id: config.entitySiteTypeId,
        entity: {
          name,
          description,
          site: site.site,
          permission,
          deployed: `${isDeploy}`,
          tour: "false",
        },
      };

      const res = await EntityApi.postEntity(obj);
      handleRes(res);
      setLoading(false);
      onClose();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleUpdate = async (isDeploy) => {
    try {
      const obj = {
        type_id: config.entitySiteTypeId,
        entity: {
          name,
          description,
          site: site.site,
          permission,
        },
      };
      if (isDeploy) {
        obj.entity.deployed = "true";
      }
      const res = await EntityApi.putEntity(site._id, obj);
      handleRes(res);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <Stack spacing={2} className="tour-siteedit">
          <TextField
            value={name}
            label="name"
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            label="description"
            multiline
          />
          <PermissionDropdownComponent
            value={permission}
            onChange={(e) => setPermission(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions className="tour-savedialog">
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => (isNew ? handleSave(false) : handleUpdate())}
          variant="outlined"
        >
          {isNew ? "Save Draft" : "Save"}
        </Button>
        {site?.deployed !== "true" && canDeploy ? (
          <Button
            onClick={() => (isNew ? handleSave(true) : handleUpdate(true))}
            variant="contained"
          >
            Deploy
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
