import React, { useState } from "react";
import { Button, Stack, TextField } from "@mui/material";
import Upload from "@mui/icons-material/FileUpload";
import { useNode } from "@craftjs/core";

import { testImage } from "../util/testImage";
import FileUploader from "../../../common/fileUploader";

export const Image = ({ width, height, alt, src, ...props }) => {
  const {
    connectors: { connect, drag },
  } = useNode((node) => ({
    imageProps: node.data.props,
  }));

  return (
    <img
      ref={(ref) => connect(drag(ref))}
      {...props}
      alt={alt}
      src={src || testImage}
      width={width}
      height={height}
    />
  );
};

export const ImageSettings = () => {
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
  }));

  const [open, setOpen] = useState(false);

  const handleUpload = (image) => {
    setProp((props) => {
      props.src = image.base64;
      props.alt = image.name;
    });
  };

  return (
    <Stack spacing={2}>
      <Button onClick={() => setOpen(true)} size="small" endIcon={<Upload />}>
        Upload
      </Button>
      <TextField
        label="width"
        size="small"
        type="number"
        value={props.width}
        onChange={(e) => setProp((props) => (props.width = e.target.value))}
      />
      <TextField
        label="height"
        size="small"
        type="number"
        value={props.height}
        onChange={(e) => setProp((props) => (props.height = e.target.value))}
      />
      <TextField
        label="alt"
        size="small"
        value={props.alt}
        onChange={(e) => setProp((props) => (props.alt = e.target.value))}
      />
      <TextField
        label="src"
        size="small"
        value={props.src}
        onChange={(e) => setProp((props) => (props.src = e.target.value))}
      />
      <FileUploader
        open={open}
        setOpen={setOpen}
        fileTypes={{ "image/jpeg": [], "image/png": [] }}
        maxFiles={1}
        onUpdate={handleUpload}
      />
    </Stack>
  );
};

const ImageDefaultProps = {
  width: 60,
  height: 60,
  alt: "image",
  src: testImage,
};

Image.craft = {
  props: ImageDefaultProps,
  related: {
    settings: ImageSettings,
  },
};
