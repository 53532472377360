import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import { SnackNotificationComponent, AuthProvider } from "@unity/components";
import { ApiLoaderContext } from "./services/apiLoaderContext";
import Loading from "./common/loading";
import Routes from "./routes";
import { TourContext } from "./services/TourContext";
import config from "./config";

export default function Root(props) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    open: false,
    message: "",
    success: "",
  });
  const [tourOpen, setTourOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("tour", function (e) {
      if (e.detail?.open) {
        setTourOpen(e.detail.open);
      } else {
        setTourOpen((prev) => !prev);
      }
    });
  }, []);

  const handleCloseSnackbar = () => {
    setMessage({ open: false, message: "", success: "" });
  };

  let display = (
    <AuthProvider module_alias={config.resourceName} {...props}>
      <ApiLoaderContext.Provider
        value={{
          setLoading,
          setSnackbar: setMessage,
        }}
      >
        <TourContext.Provider value={tourOpen}>
          <Router>
            <Route
              render={({ location }) => (
                <TransitionGroup className="transition-group">
                  <CSSTransition
                    key={location.key}
                    timeout={300}
                    classNames="fade"
                  >
                    <section className="route-section">
                      <Routes />
                      <SnackNotificationComponent
                        open={message.open}
                        message={message.message}
                        success={message.success}
                        onClose={handleCloseSnackbar}
                      />
                      <Loading open={loading} />
                    </section>
                  </CSSTransition>
                </TransitionGroup>
              )}
            />
          </Router>
        </TourContext.Provider>
      </ApiLoaderContext.Provider>
    </AuthProvider>
  );

  return display;
}
