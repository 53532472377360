import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { Editor, Frame } from "@craftjs/core";
import lz from "lzutf8";

import config from "../config";
import { EntityApi } from "@unity/components";

import { Text } from "../components/craftjs/components/text";
import { Link } from "../components/craftjs/components/link";
import { Container } from "../components/craftjs/components/container";
import { Image } from "../components/craftjs/components/image";
import { Video } from "../components/craftjs/components/video";
import { Divider } from "../components/craftjs/components/divider";
import { Button } from "../components/craftjs/components/button";

export default function SiteViewer() {
  const { id } = useParams();

  const [json, setJson] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const res = await EntityApi.getEntity(id, config.entitySiteTypeId);
      if (res && res.success) {
        const json = lz.decompress(lz.decodeBase64(res.data.site));
        if (json) setJson(json);
      }
    };
    getData();
  }, [id]);

  return json ? (
    <Box
      sx={{ mt: 1, width: "100%", display: "flex", justifyContent: "center" }}
    >
      <Editor
        resolver={{ Text, Link, Container, Image, Divider, Video, Button }}
        enabled={false}
      >
        <Frame data={json} />
      </Editor>
    </Box>
  ) : null;
}
