import React from "react";
import {
  TextField,
  Link as MuiLink,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import { useNode, useEditor } from "@craftjs/core";

import { getSelectedStyle } from "../util/getSelectedStyle";

export const Link = ({ label, url, ...props }) => {
  const { enabled, currentNodeId } = useEditor((state, query) => ({
    enabled: state.options.enabled,
    currentNodeId: query.getEvent("selected").last(),
  }));

  const {
    id,
    connectors: { connect, drag },
  } = useNode((state) => ({
    selected: state.events.selected,
  }));

  return enabled ? (
    <Tooltip title={url} placement="bottom-start">
      <Typography
        ref={(ref) => connect(drag(ref))}
        {...props}
        color="primary.main"
        sx={getSelectedStyle(null, currentNodeId, id)}
      >
        {label}
      </Typography>
    </Tooltip>
  ) : (
    <MuiLink href={url}>{label}</MuiLink>
  );
};

export const LinkSettings = () => {
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
  }));
  return (
    <Stack spacing={2}>
      <TextField
        label="url"
        size="small"
        value={props.url}
        onChange={(e) => setProp((props) => (props.url = e.target.value))}
      />
      <TextField
        label="label"
        size="small"
        value={props.label}
        onChange={(e) => setProp((props) => (props.label = e.target.value))}
      />
    </Stack>
  );
};

const LinkDefaultProps = {
  label: "link url",
  url: "#",
};

Link.craft = {
  props: LinkDefaultProps,
  related: {
    settings: LinkSettings,
  },
};
