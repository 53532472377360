import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button as MuiButton,
  Stack,
  TextField,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Input,
  Box,
} from "@mui/material";
import { useEditor, useNode } from "@craftjs/core";

import { useCraftjsComponentStyle } from "../../../utils/useCraftjsComponentStyle";

export const Button = ({
  url,
  label,
  onClick,
  color,
  backgroundColor,
  variant,
  ...props
}) => {
  const {
    id,
    isHover,
    connectors: { connect, drag },
  } = useNode((node) => ({
    isHover: node.events.hovered,
  }));
  const { enabled } = useEditor((state, query) => ({
    isActive: query.getEvent("selected").contains(id),
    enabled: state.options.enabled,
  }));
  const nodeStyle = useCraftjsComponentStyle(id, isHover);

  return (
    <MuiButton
      ref={(ref) => connect(drag(ref))}
      onClick={enabled ? (e) => e.preventDefault() : onClick}
      component={RouterLink}
      to={url}
      variant={variant}
      style={{ color, backgroundColor, ...nodeStyle }}
      {...props}
    >
      {label}
    </MuiButton>
  );
};

export const ButtonSettings = () => {
  const {
    actions: { setProp },
    props,
  } = useNode((node) => ({
    props: node.data.props,
  }));
  return (
    <Stack spacing={2}>
      <TextField
        label="url"
        size="small"
        value={props.url}
        onChange={(e) => setProp((props) => (props.url = e.target.value))}
      />
      <TextField
        label="label"
        size="small"
        value={props.label}
        onChange={(e) => setProp((props) => (props.label = e.target.value))}
      />
      <FormControl size="small">
        <FormLabel id="variant">Variant</FormLabel>
        <Select
          labelId="variant"
          value={props.variant}
          onChange={(e) => setProp((props) => (props.variant = e.target.value))}
        >
          <MenuItem value="contained">Contained</MenuItem>
          <MenuItem value="text">Text</MenuItem>
          <MenuItem value="outlined">Outlined</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <FormControl size="small" margin="normal" component="fieldset">
          <FormLabel component="legend">Color</FormLabel>
          <Input
            type="color"
            size="small"
            disableUnderline
            value={props.color}
            onChange={(e) => {
              setProp((props) => (props.color = e.target.value), 500);
            }}
            sx={{ ".MuiInput-input": { height: 30, width: 60 } }}
          />
        </FormControl>
        <FormControl size="small" margin="normal" component="fieldset">
          <FormLabel component="legend">Background color</FormLabel>
          <Input
            type="color"
            size="small"
            disableUnderline
            value={props.backgroundColor}
            onChange={(e) => {
              setProp((props) => (props.backgroundColor = e.target.value), 500);
            }}
            sx={{ ".MuiInput-input": { height: 30, width: 60 } }}
          />
        </FormControl>
      </Box>
    </Stack>
  );
};

const ButtonDefaultProps = {
  url: "#",
  label: "link url",
  variant: "contained",
  color: "#eeeeee",
  backgroundColor: "#1e88e5",
};

Button.craft = {
  props: ButtonDefaultProps,
  related: {
    settings: ButtonSettings,
  },
};
