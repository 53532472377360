import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./pages/index";
import SiteEditor from "./pages/siteEditor";
import SiteViewer from "./pages/siteViewer";
import { useAuth } from "@unity/components";
import config from "./config";

export default function Routes() {
  const { auth } = useAuth();
  const allowUser = auth.access[config.resourceName];

  return (
    <Switch>
      <Route path="/location" exact component={Location} />
      {allowUser && (
        <Route exact path="/site-creator/index">
          <Home />
        </Route>
      )}
      {allowUser && (
        <Route exact path="/site-creator/new">
          <SiteEditor />
        </Route>
      )}
      {allowUser && (
        <Route path="/site-creator/edit/:id">
          <SiteEditor />
        </Route>
      )}
      {allowUser && (
        <Route path="/site-creator/deployed/:id">
          <SiteViewer />
        </Route>
      )}
      {allowUser && (
        <Route path="/site-creator/:id">
          <SiteEditor readOnly />
        </Route>
      )}
    </Switch>
  );
}
