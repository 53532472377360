import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Paper,
  Divider as MuiDivider,
} from "@mui/material";
import { useEditor, Element as CraftElement } from "@craftjs/core";
import Undo from "@mui/icons-material/Undo";
import Redo from "@mui/icons-material/Redo";
import LinkIcon from "@mui/icons-material/Link";
import Http from "@mui/icons-material/Http";
import TitleIcon from "@mui/icons-material/Title";
import ImageIcon from "@mui/icons-material/Image";
import CropLandscape from "@mui/icons-material/CropLandscape";
import Height from "@mui/icons-material/Height";
import YouTube from "@mui/icons-material/YouTube";
import Save from "@mui/icons-material/Save";
import Upload from "@mui/icons-material/Upload";
import lz from "lzutf8";

import { Link } from "./components/link";
import { Text } from "./components/text";
import { Image } from "./components/image";
import { Container } from "./components/basicContainer";
import { Divider } from "./components/divider";
import { Video } from "./components/video";
import { Button } from "./components/button";
import { useTour } from "../../services/TourContext";

const styles = {
  drag: {
    cursor: "grab",
  },
};

const testing = false;

export const Toolbox = ({
  readOnly,
  site,
  setSite,
  onSaveClick,
  preview,
  setPreview,
}) => {
  const history = useHistory();
  const tourOpen = useTour();
  const { enabled, canUndo, canRedo, actions, connectors, query } = useEditor(
    (state, query) => ({
      enabled: readOnly ? readOnly : state.options.enabled,
      canUndo: query.history.canUndo(),
      canRedo: query.history.canRedo(),
    })
  );
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [saveData, setSaveData] = useState(null);
  const [confirmCloseOpen, setConfirmCloseOpen] = useState(false);

  useEffect(() => {
    if (site && site.site) {
      const json = lz.decompress(lz.decodeBase64(site.site));
      actions.deserialize(json);
    }
  }, [site?.site, actions, site]);

  const handleLoad = () => {
    const json = lz.decompress(lz.decodeBase64(data));
    actions.deserialize(json);
    setOpen(false);
  };
  const handleSave = () => {
    if (tourOpen) {
      window.dispatchEvent(
        new CustomEvent("tourStepIndex", { detail: { stepIndex: 1 } })
      );
    }
    const data = query.serialize();
    let localSite = site ? JSON.parse(JSON.stringify(site)) : {};
    localSite.site = lz.encodeBase64(lz.compress(data));
    setSite(localSite);
  };

  const handleClose = () => {
    setOpen(false);
    setSaveData(null);
  };

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        bgcolor: (theme) => theme.palette.background.paper,
        p: 1,
        zIndex: 2000,
      }}
    >
      {enabled ? (
        <Stack spacing={1} direction="row">
          <div className="tour-siteundo" style={{ display: "flex" }}>
            <Tooltip title="Undo">
              <span>
                <IconButton
                  disabled={!canUndo}
                  onClick={() => actions.history.undo()}
                >
                  <Undo />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Redo">
              <span>
                <IconButton
                  disabled={!canRedo}
                  onClick={() => actions.history.redo()}
                >
                  <Redo />
                </IconButton>
              </span>
            </Tooltip>
          </div>
          <MuiDivider orientation="vertical" flexItem />
          <Tooltip title="add text">
            <IconButton
              ref={(ref) => connectors.create(ref, <Text text="Hi world" />)}
              data-cy="toolbox-text"
              sx={styles.drag}
              className="tour-sitetext"
            >
              <TitleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="add link">
            <IconButton
              ref={(ref) =>
                connectors.create(ref, <Link label="default url" />)
              }
              variant="contained"
              data-cy="toolbox-link"
              sx={styles.drag}
              className="tour-sitelink"
            >
              <Http />
            </IconButton>
          </Tooltip>
          <Tooltip title="add image">
            <IconButton
              ref={(ref) =>
                connectors.create(ref, <Image src="" alt="testing" />)
              }
              variant="contained"
              data-cy="toolbox-image"
              sx={styles.drag}
              className="tour-sitepicture"
            >
              <ImageIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="add video">
            <IconButton
              ref={(ref) =>
                connectors.create(
                  ref,
                  <Video url="https://www.youtube.com/embed/XfAdPsKCIG4?si=2BsSootcDom-toXe" />
                )
              }
              variant="contained"
              data-cy="toolbox-video"
              sx={styles.drag}
              className="tour-sitevideo"
            >
              <YouTube />
            </IconButton>
          </Tooltip>
          <Tooltip title="add container">
            <IconButton
              ref={(ref) =>
                connectors.create(
                  ref,
                  <CraftElement
                    canvas
                    is={Container}
                    padding={5}
                    background="#ffffff"
                    width={500}
                    height={100}
                  />
                )
              }
              variant="contained"
              data-cy="toolbox-container"
              sx={styles.drag}
              className="tour-sitecontainer"
            >
              <CropLandscape />
            </IconButton>
          </Tooltip>
          <Tooltip title="add button">
            <IconButton
              ref={(ref) => connectors.create(ref, <Button label="click me" />)}
              variant="contained"
              data-cy="toolbox-Button"
              sx={styles.drag}
              className="tour-sitebutton"
            >
              <LinkIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="add spacing">
            <IconButton
              ref={(ref) =>
                connectors.create(ref, <Divider height={50} width={50} />)
              }
              variant="contained"
              data-cy="toolbox-container"
              sx={styles.drag}
              className="tour-sitespacing"
            >
              <Height />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : (
        <div />
      )}
      <Stack spacing={1} direction="row">
        {testing && enabled ? (
          <Tooltip title="Save current content (test)">
            <IconButton onClick={handleSave}>
              <Save />
            </IconButton>
          </Tooltip>
        ) : null}
        {testing && enabled ? (
          <Tooltip title="load content (test)">
            <IconButton onClick={() => setOpen(true)}>
              <Upload />
            </IconButton>
          </Tooltip>
        ) : null}
        <MuiButton
          variant="outlined"
          onClick={() => history.push("/site-creator/index")}
          className="tour-closebutton"
        >
          Cancel
        </MuiButton>
        <MuiButton
          variant="outlined"
          onClick={() => setPreview((prev) => !prev)}
          className="tour-previewbutton"
        >
          {preview ? "Close " : ""} Preview
        </MuiButton>
        <MuiButton
          variant="contained"
          onClick={() => {
            handleSave();
            onSaveClick();
          }}
          disabled={preview}
          className="tour-savebutton"
        >
          Save
        </MuiButton>
      </Stack>
      <Dialog
        open={confirmCloseOpen}
        onClose={() => setConfirmCloseOpen(false)}
      >
        <DialogContent>
          <DialogContentText>
            Are you sure you want to discard any changes you have made?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={() => setConfirmCloseOpen(false)}>
            cancel
          </MuiButton>
          <MuiButton
            onClick={() =>
              actions.setOptions((options) => (options.enabled = false))
            }
            variant="contained"
          >
            Discard
          </MuiButton>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogContent>
          {saveData ? (
            <Typography sx={{ width: "100%", overflowWrap: "break-word" }}>
              {saveData}
            </Typography>
          ) : (
            <TextField
              multiline
              value={data}
              onChange={(e) => setData(e.target.value)}
              fullWidth
            />
          )}
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleClose}>Cancel</MuiButton>
          {!saveData ? <MuiButton onClick={handleLoad}>Load</MuiButton> : null}
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
