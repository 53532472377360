import React from "react";
import { useEditor, useNode } from "@craftjs/core";
import { Stack, TextField } from "@mui/material";

export const Video = ({ url, ...props }) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  return (
    <div
      ref={(ref) => connect(drag(ref))}
      style={{
        padding: enabled ? 10 : 0,
      }}
      {...props}
    >
      <iframe
        src={url}
        border="0"
        allow="autoplay; encrypted-media; accelerometer; gyroscope; picture-in-picture; web-share; clipboard-write"
        allowfullscreen
        title="video"
        style={{
          pointerEvents: enabled ? "none" : "auto",
          width: 300,
          height: 150,
        }}
      />
    </div>
  );
};

export const VideoSettings = () => {
  const {
    actions: { setProp },
    url,
  } = useNode((node) => ({
    url: node.data.props.url,
  }));

  return (
    <Stack spacing={1}>
      <TextField
        value={url}
        onChange={(e) => setProp((props) => (props.url = e.target.value))}
        label="url"
        size="small"
      />
    </Stack>
  );
};

const defaultVideoProps = {
  url: "https://www.youtube.com/embed/XfAdPsKCIG4?si=2BsSootcDom-toXe",
};

Video.craft = {
  props: defaultVideoProps,
  related: {
    settings: VideoSettings,
  },
};
