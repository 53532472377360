import { useEffect, useState } from "react";
import { useEditor, useNode } from "@craftjs/core";

export const useCraftjsComponentStyle = (id, isHover) => {
  const { isActive, enabled } = useEditor((state, query) => ({
    isActive: query.getEvent("selected").contains(id),
    enabled: state.options.enabled,
  }));

  const [nodeStyle, setNodeStyle] = useState({});

  useEffect(() => {
    if (enabled) {
      if (isActive) {
        setNodeStyle({ border: "1px solid blue" });
      } else if (isHover) {
        setNodeStyle({ border: "1px dashed red" });
      } else {
        setNodeStyle({ border: "1px dashed #aaa" });
      }
    } else {
      setNodeStyle({});
    }
  }, [isActive, isHover, enabled, id]);

  return nodeStyle;
};
