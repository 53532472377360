const apiBase =
  process.env.NODE_ENV === "production"
    ? window.location.hostname
    : "http://localhost:8080";
const clientBase =
  process.env.NODE_ENV === "production"
    ? window.location.hostname
    : "http://localhost:9000";
module.exports = {
  // ports
  serverPort: 8080,
  //This app url
  appURL: apiBase,
  clientURL: clientBase,
  entitySiteTypeId: "65a7c99e4195554e3c0025f2",
  resourceName: "site-creator",
  defaultRole: "manager",
};
