import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Editor, Element as CraftElement, Frame } from "@craftjs/core";

import { Text } from "./components/text";
import { Link } from "./components/link";
import { Container } from "./components/basicContainer";
import { Image } from "./components/image";
import { Video } from "./components/video";
import { Divider } from "./components/divider";
import { Button } from "./components/button";
import { Toolbox } from "./toolbox";
import { Settings } from "./settings";

const exampleText = `
Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
when an unknown printer took a galley of type and scrambled it to make a type specimen book.
 It has survived not only five centuries, but also the leap into electronic typesetting, 
 remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset 
 sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
`;

const exampleText2 = `
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla id dui ut nunc facilisis feugiat. Donec eget neque nibh. Nullam tincidunt risus hendrerit lacus lobortis egestas. Morbi a quam sapien. Maecenas et facilisis nisi. Aenean suscipit feugiat nisi quis semper. Maecenas urna risus, rhoncus eget eros et, dictum commodo ex. Aenean laoreet felis nec erat aliquam, vel scelerisque felis fringilla. Aenean suscipit ac enim id eleifend. Curabitur erat tortor, molestie a rutrum vitae, blandit vel libero. Phasellus non suscipit tellus. Ut convallis, turpis id accumsan consectetur, dolor urna varius turpis, in efficitur lacus velit non est. Vestibulum est turpis, cursus eu condimentum sed, ornare ut mauris. Proin sed nisi facilisis, finibus nisi quis, condimentum ipsum.

Ut magna arcu, laoreet non accumsan ut, tristique vel lacus. Maecenas pharetra laoreet nisi, tincidunt tempor metus consectetur ac. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam eu nisi risus. Morbi sed sapien tempus diam ultricies fringilla eu sagittis velit. Fusce nec facilisis enim. Sed eu nisi lectus. Nullam consectetur ante turpis, sit amet viverra libero mattis vel. Integer vitae malesuada nisi. Nam mattis venenatis odio, id volutpat ante congue vitae. Donec nec elit sit amet lectus condimentum venenatis. Integer sit amet metus eget quam hendrerit congue id nec eros.

Pellentesque pretium congue aliquam. Sed vehicula leo vitae lacus aliquet, id efficitur massa placerat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed vehicula semper arcu, a iaculis turpis aliquam non. Sed vehicula sem sit amet tellus rhoncus, commodo aliquam est porta. Nam lacinia vel diam id dignissim. Donec maximus fermentum tellus, eget rutrum lacus lacinia eget. Praesent nec dapibus eros, nec malesuada sem. Maecenas a commodo urna, sit amet rhoncus odio. Nam ornare neque in orci vestibulum vestibulum. Curabitur sed maximus nulla. Aliquam sit amet enim nec odio mattis sagittis. Curabitur metus urna, tempus a suscipit vitae, posuere sed tortor. Nunc gravida suscipit auctor. Pellentesque sit amet risus et erat fringilla pharetra. Aenean ligula nibh, finibus eget odio vel, cursus tempor metus.`;

export const SiteGenerator = ({ site, setSite, readOnly, onSaveClick }) => {
  const [preview, setPreview] = useState(false);

  return (
    <Editor
      resolver={{
        Text,
        Link,
        Container,
        Image,
        Divider,
        Video,
        Button,
      }}
      enabled={!preview}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          style={{
            width: "100%",
            position: "sticky",
            top: 0,
            alignSelf: "flex-start",
            zIndex: 5000,
          }}
          className="tour-sitetoolbar"
        >
          <Toolbox
            readOnly={readOnly}
            site={site}
            setSite={setSite}
            onSaveClick={onSaveClick}
            preview={preview}
            setPreview={setPreview}
          />
        </Grid>
        <Grid item className="tour-siteeditortry">
          {
            <Frame>
              {
                <CraftElement
                  canvas
                  is={Container}
                  padding={5}
                  background="#f5f5f5"
                  data-cy="root-container"
                  width={"auto"}
                  height={"auto"}
                >
                  <CraftElement
                    canvas
                    is={Container}
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    width={590}
                    height={60}
                    data-cy="appbar"
                  >
                    <CraftElement
                      canvas
                      is={Container}
                      flexDirection="row"
                      alignItems="center"
                      width={300}
                      height={55}
                      padding={0}
                      data-cy="toolbar"
                    >
                      <Button label="Home" data-cy="buttonhome" />
                      <Button label="about" data-cy="buttonabout" />
                    </CraftElement>
                    <Button label="My Account" data-cy="buttonaccount" />
                  </CraftElement>
                  <Text
                    bold={true}
                    text="My Site"
                    fontSize={22}
                    data-cy="frame-container-text"
                  />
                  <Text text={exampleText} fontSize={14} color="#675f5f" />
                  <CraftElement
                    canvas
                    is={Container}
                    background="white"
                    gap={10}
                    width={590}
                    height={200}
                    padding={10}
                  >
                    <Text bold={true} fontSize={18} text="Heading 1" />
                    <Text text="Some important text" />
                    <Text text="Some more important text" />
                  </CraftElement>
                  <CraftElement canvas is={Container}>
                    <Text text={exampleText2} />
                  </CraftElement>
                </CraftElement>
              }
            </Frame>
          }
        </Grid>
        <Grid
          item
          style={{
            position: "sticky",
            top: 110,
            alignSelf: "flex-start",
          }}
        >
          <Settings />
        </Grid>
      </Grid>
    </Editor>
  );
};
