import React from "react";
import { useEditor } from "@craftjs/core";
import { Box, Stack, Typography } from "@mui/material";

import {
  LoadingComponent,
  DeleteButtonComponent,
  TourButtonComponent,
} from "@unity/components";

export const Settings = () => {
  const { actions, selected } = useEditor((state, query) => {
    const currentNodeId = query.getEvent("selected").last();
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected,
      isEnabled: state.options.enabled,
    };
  });

  return selected ? (
    <Box sx={{ p: 2, bgcolor: "white", minWidth: 340 }}>
      <Typography color="textSecondary">Customize</Typography>
      <Stack spacing={1}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>{selected.name || "Selected"}</Typography>
          <TourButtonComponent
            style={{
              visibility: selected?.name === "Container" ? "visible" : "hidden",
            }}
            onClick={() => {
              window.dispatchEvent(
                new CustomEvent("tourOpen", {
                  detail: { module: `site-component-${selected?.name}` },
                })
              );
            }}
          />
        </div>
        <div data-cy="settings-panel">
          {selected.settings && React.createElement(selected.settings)}
        </div>
        {selected.isDeletable ? (
          <DeleteButtonComponent
            onClick={() => {
              actions.delete(selected.id);
            }}
          >
            Delete
          </DeleteButtonComponent>
        ) : null}
      </Stack>
      <LoadingComponent />
    </Box>
  ) : (
    <Box sx={{ p: 2 }}>
      <Typography color="textSecondary">Customize</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "80%",
          pt: 4,
        }}
      >
        <Typography sx={{ fontSize: 12 }} align="center">
          Click a component to see settings available
        </Typography>
      </Box>
    </Box>
  );
};
