import {
  Paper,
  Divider,
  Button,
  Grid,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Add from "@mui/icons-material/Add";

import { EntityApi, useAuth } from "@unity/components";
import config from "../config";
import { checkArray } from "../utils/checkArray";
import SiteCard from "../components/siteCard";
import { useTour } from "../services/TourContext";

export default function Home() {
  const { auth, resourceActions, checkPermission } = useAuth();
  const tourOpen = useTour();

  const [draftSites, setDraftSites] = useState([]);
  const [sites, setSites] = useState([]);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    const querySites = async () => {
      const availableRoles = resourceActions
        .filter(
          (el) =>
            el.name.indexOf(`read_sites`) !== -1 ||
            el.name.indexOf(`read_allsites`) !== -1
        )
        .map((el) => {
          const lr = el ? el.name.split("_") : [];
          return lr[2];
        });
      console.log("availabel roles: ", availableRoles);
      const obj = (deployed) => {
        let queryArr = [
          { AND: ["deployed", `${deployed}`] },
          { AND: ["tour", `${tourOpen}`] },
          { IN: ["permission", [...availableRoles]] },
        ];
        if (
          !tourOpen &&
          !resourceActions.some((el) => el.name.indexOf("read_allsites") !== -1)
        )
          queryArr.push({ AND: ["created_by", auth.id] });
        return { type_id: config.entitySiteTypeId, queries: queryArr };
      };
      const res = [
        EntityApi.queryEntityWithinType(obj(true)),
        EntityApi.queryEntityWithinType(obj(false)),
      ];
      Promise.all(res).then((res) => {
        const checkRes = res.every((el) => el.success);
        if (checkRes) {
          res.forEach((el, i) => {
            if (i === 0) setSites(el.data.data);
            else setDraftSites(el.data.data);
          });
        }
      });
    };
    querySites();
  }, [update, auth.id, tourOpen, resourceActions]);

  return (
    <Paper elevation={0} sx={{ pt: 1, pl: 1 }}>
      <Typography gutterBottom variant="h5">
        Site Creator
      </Typography>
      <Stack spacing={3} sx={{ pl: 1 }}>
        {checkPermission("create_site_user") ? (
          <Button
            variant="outlined"
            component={RouterLink}
            to={"/site-creator/new"}
            endIcon={<Add />}
            sx={{ width: 250 }}
            className="tour-newsitebutton"
          >
            create new site
          </Button>
        ) : null}
        <Divider />
        <Typography variant="h6">Deployed Sites</Typography>
        <Box
          className="tour-deployedsites"
          sx={{ maxHeight: 400, overflow: "auto" }}
        >
          <Grid container spacing={1}>
            {checkArray(sites)
              ? sites.map((el, i) => (
                  <Grid
                    item
                    key={el._id}
                    xs={12}
                    md={4}
                    className={i === 0 ? "tour-sitecard" : ""}
                  >
                    <SiteCard site={el} setUpdate={setUpdate} />
                  </Grid>
                ))
              : null}
          </Grid>
        </Box>
        <Divider />
        <Typography variant="h6">Draft Sites</Typography>
        <Box
          className="tour-draftsites"
          sx={{ maxHeight: 400, overflow: "auto" }}
        >
          <Grid container spacing={1}>
            {checkArray(draftSites)
              ? draftSites.map((el) => (
                  <Grid item key={el._id} xs={12} md={4}>
                    <SiteCard site={el} setUpdate={setUpdate} />
                  </Grid>
                ))
              : null}
          </Grid>
        </Box>
      </Stack>
    </Paper>
  );
}
