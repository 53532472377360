import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MoreHoriz from "@mui/icons-material/MoreHoriz";

import SiteCardMenu from "./siteCardMenu";
import { useAuth } from "@unity/components";

export default function SiteCard({ site, setUpdate }) {
  const { auth } = useAuth();
  const [open, setOpen] = useState(null);
  return (
    <Paper sx={{ p: 1 }} variant="outlined">
      <Stack spacing={1} alignItems="flex-start">
        <Box
          sx={{
            display: "flex",
            gap: 1,
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Tooltip title={site?.name}>
            <Typography noWrap sx={{ fontWeight: "bold" }}>
              {site?.name}
            </Typography>
          </Tooltip>
          <IconButton
            sx={{
              visibility: site?.created_by === auth.id ? "visible" : "hidden",
            }}
            onClick={(e) => setOpen(e.currentTarget)}
            className="tour-sitemenu"
          >
            <MoreHoriz />
          </IconButton>
        </Box>
        <Typography variant="body2" color="textSecondary">
          Permission - {site?.permission ? site.permission : "None"}
        </Typography>
        <Tooltip title={site?.description}>
          <Typography
            variant="body2"
            noWrap
            sx={{
              width: "100%",
              visibility: site?.description ? "visible" : "hidden",
            }}
          >
            {site?.description ? site.description : "this is a description"}
          </Typography>
        </Tooltip>
      </Stack>
      <SiteCardMenu
        open={open}
        onClose={() => setOpen(null)}
        site={site}
        setUpdate={setUpdate}
      />
    </Paper>
  );
}
