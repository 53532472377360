import React, { useState, useEffect } from "react";
import { useEditor, useNode } from "@craftjs/core";
import ContentEditable from "react-contenteditable";
import {
  Checkbox,
  Slider,
  FormControl,
  FormLabel,
  Stack,
  FormControlLabel,
  Input,
} from "@mui/material";

export const Text = ({ text, fontSize, bold, color, textAlign, ...props }) => {
  const {
    connectors: { connect, drag },
    selected,
    actions: { setProp },
  } = useNode((state) => ({
    selected: state.events.selected,
    dragged: state.events.dragged,
  }));

  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selected) {
      return;
    }
    setEditable(false);
  }, [selected]);

  return (
    <div
      {...props}
      ref={(ref) => connect(drag(ref))}
      onClick={() => selected && setEditable(true)}
    >
      <ContentEditable
        html={text}
        disabled={!editable}
        onChange={(e) =>
          setProp(
            (props) =>
              (props.text = e.target.value.replace(/<\/?[^>]+(>|$)/g, "")),
            500
          )
        }
        style={{
          fontSize: `${fontSize}px`,
          fontWeight: bold ? "700" : "400",
          textAlign,
          color,
          margin: 0,
          padding: 3,
          whiteSpace: "pre-wrap",
          border: enabled ? "1px dashed #aaa" : 0,
          cursor: enabled && editable ? "text" : "grab",
        }}
      />
    </div>
  );
};

const TextSettings = () => {
  const {
    actions: { setProp },
    fontSize,
    bold,
    color,
  } = useNode((node) => ({
    text: node.data.props.text,
    fontSize: node.data.props.fontSize,
    bold: node.data.props.bold,
    color: node.data.props.color,
  }));

  return (
    <Stack spacing={2}>
      <FormControl size="small" component="fieldset">
        <FormLabel component="legend">
          Font size
          <span
            style={{ color: "#000000", paddingLeft: 10 }}
          >{`${fontSize}px`}</span>
        </FormLabel>
        <Slider
          size="small"
          value={fontSize || 7}
          min={5}
          max={50}
          onChange={(_, value) => {
            setProp((props) => (props.fontSize = value), 1000);
          }}
        />
      </FormControl>
      <FormControlLabel
        size="small"
        label="Bold?"
        control={
          <Checkbox
            checked={bold}
            onChange={(e) =>
              setProp((props) => (props.bold = e.target.checked))
            }
          />
        }
      />
      <FormControl size="small" margin="normal" component="fieldset">
        <FormLabel component="legend">Color</FormLabel>
        <Input
          type="color"
          size="small"
          disableUnderline
          value={color}
          onChange={(e) => {
            setProp((props) => (props.color = e.target.value), 500);
          }}
          sx={{ ".MuiInput-input": { height: 30, width: 60 } }}
        />
      </FormControl>
    </Stack>
  );
};

export const TextDefaultProps = {
  text: "Hi",
  fontSize: 14,
  bold: false,
  color: "#000000",
};

Text.craft = {
  props: TextDefaultProps,
  related: {
    settings: TextSettings,
  },
};
