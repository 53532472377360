import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

export default function Loading({ open }) {
  return (
    <Backdrop open={open}>
      <CircularProgress />
    </Backdrop>
  );
}
